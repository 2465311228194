// @ts-nocheck

import SmileOutlined from '@ant-design/icons/SmileOutlined';
import UserOutlined from '@ant-design/icons/UserOutlined';
import RobotOutlined from '@ant-design/icons/RobotOutlined';
import TableOutlined from '@ant-design/icons/TableOutlined';
import ToolOutlined from '@ant-design/icons/ToolOutlined';
import MessageOutlined from '@ant-design/icons/MessageOutlined';
import SortAscendingOutlined from '@ant-design/icons/SortAscendingOutlined';
import DeploymentUnitOutlined from '@ant-design/icons/DeploymentUnitOutlined'

export default {
  SmileOutlined,
UserOutlined,
RobotOutlined,
TableOutlined,
ToolOutlined,
MessageOutlined,
SortAscendingOutlined,
DeploymentUnitOutlined
}
    